import { configureStore,Tuple } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSagas from './sagas';
import WatchGoogleAnalyticsEventsSaga from './sagas/WatchGoogleAnalyticsEventsSaga'

import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

import rootReducer from './reducers';
import logger from 'redux-logger';

const sagaMiddleware = createSagaMiddleware(); // creates redux saga middleware
const browserWindow = window;

const rootConfig = {
    key: "root",
    storage,
    whitelist: ["Auth"],
};

const persistedReducer = persistReducer(rootConfig, rootReducer);
const store = configureStore({
    reducer: persistedReducer,
    middleware: () => new Tuple(sagaMiddleware, logger),
    devTools:
      browserWindow.__REDUX_DEVTOOLS_EXTENSION__ &&
      browserWindow.__REDUX_DEVTOOLS_EXTENSION__(),
});

sagaMiddleware.run(rootSagas);
sagaMiddleware.run(WatchGoogleAnalyticsEventsSaga);

let persistor = persistStore(store);

export { store, persistor }; 



