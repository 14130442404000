import {
	GET_USER_FOUND,
	GET_USER_NOT_FOUND,
	GET_USER_REQUEST,
	LOGOUT_SUCCESS,
	LOGOUT,
	LOGOUT_ERROR,
	DELETE_ACCOUNT_REQUEST,
	DELETE_ACCOUNT_SUCCESS,
	DELETE_ACCOUNT_FAILED,
	UPDATE_USER_AFTER_CLAIM,
	UPDATE_USER_LEVEL_REQUEST,
	UPDATE_USER_BALANCE_REQUEST,
	UPDATE_LANGUAGE_SUCCESS,
	UPDATE_LANGUAGE_REQUEST,
	UPDATE_LANGUAGE_FAILED,
} from "../actions/authActions";

const initialState = {
	loading: false,
	user: {},
	token: ""
};

export const AuthReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_USER_REQUEST:
			return {
				...state,
				loading: true,
			};
		case GET_USER_FOUND:
			return {
				...state,
				user: action?.payload,
				token: action?.token,
				loading: false,
			};
		case GET_USER_NOT_FOUND:
			return { ...initialState };
		case LOGOUT:
			return {
				...state,
				loading: true,
			};
		case LOGOUT_SUCCESS:
			localStorage.removeItem("token");
			localStorage.removeItem("click_count");
			sessionStorage.removeItem("token");
			sessionStorage.removeItem("click_count");
			return {
				...state,
				loading: false,
				user: {},
				token: ""
			};
		case LOGOUT_ERROR:
			return {
				...state,
				loading: false,
			};
		case DELETE_ACCOUNT_REQUEST:
			return {
				...state,
				loading: true,
			};
		case DELETE_ACCOUNT_SUCCESS:
			sessionStorage.removeItem("token");
			sessionStorage.removeItem("click_count");
			localStorage.clear();
			return {
				...state,
				loading: false,
				user: {},
				token: ""
			};
		case DELETE_ACCOUNT_FAILED:
			return {
				...state,
				loading: false,
			};
		case UPDATE_USER_AFTER_CLAIM:
			return {
				...state,
				loading: false,
				user: action?.payload,
			};
		case UPDATE_USER_LEVEL_REQUEST:
			return {
				...state,
				user: action?.payload,
			};
		case UPDATE_USER_BALANCE_REQUEST:
			return {
				...state,
				user: action?.payload,
			};
		case UPDATE_LANGUAGE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case UPDATE_LANGUAGE_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case UPDATE_LANGUAGE_FAILED:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};
