import { Suspense, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./App.css";
import AppRoutes from "./routes";
import DynamicPopUpComponent from "./components/UI/DynamicPopUpComponent";
import { clearMessageAction } from "./redux/actions/messageActions";
import { useEnergy } from "./hooks/useEnergy";
import DailyRewardPopup from "./components/DailyRewardPopup";

const intervalTimer = (callback, interval = 1000) => {
  let counter = 1;
  let timeoutId;
  const startTime = Date.now();

  const main = () => {
    const nowTime = Date.now();
    const nextTime = startTime + counter * interval;
    timeoutId = setTimeout(main, interval - (nowTime - nextTime));

    counter += 1;
    callback();
  };

  timeoutId = setTimeout(main, interval);

  return () => {
    clearTimeout(timeoutId);
  };
};

function App() {
  const [showPopup, setShowPopup] = useState(false);
  const [messageData, setMessageData] = useState({});
  const MessageState = useSelector((state) => state.Message);
  const { message } = MessageState;
  const { increase: increaseEnergy } = useEnergy();

  const dispatch = useDispatch();

  useEffect(() => {
    if (message && message.length > 0) {
      setMessageData(message[0]);
      setShowPopup(true);
    } else {
      setMessageData({});
      setShowPopup(false);
    }
  }, [message]);

  const onMessagePoupClose = () => {
    dispatch(clearMessageAction());
    setShowPopup(false);
    setMessageData({});
  };

  useEffect(() => {
    intervalTimer(() => increaseEnergy());
  }, []);

  return (
    <>
      <Suspense fallback="loading">
        <AppRoutes />
        {showPopup && (
          <DynamicPopUpComponent
            isModalOpen={showPopup}
            closeModal={onMessagePoupClose}
            modalData={messageData}
          />
        )}
        {/* <DailyRewardPopup /> */}
      </Suspense>
    </>
  );
}

export default App;
