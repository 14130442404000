import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoginAction } from "../redux/actions/authActions.js";
import { telegramState } from "./telegramState.js";
import {
	ChatBackButtonClickAction,
	ChatPlayButtonClickAction,
	ChatStartClickAction,
} from "../redux/actions/googleAnalyticsActions.js";

export function useTelegram() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const initTelegram = () => {
		if (telegramState.initialized || telegramState.initializing) {
			return;
		}
		telegramState.initializing = true;
		const script = document.createElement("script");
		script.src = "https://telegram.org/js/telegram-web-app.js";
		script.async = true;
		script.onload = onDocumentLoad;
		document.body.appendChild(script);
	};

	const onDocumentLoad = () => {
		if (document.readyState !== "loading") {
			initializeTelegramWebApp();
			return;
		}
		document.addEventListener("DOMContentLoaded", function () {
			initializeTelegramWebApp();
		});
	};

	const initializeTelegramWebApp = () => {
		if (sessionStorage.getItem("token")) {
			window.Telegram.WebApp.setHeaderColor("#000000", true);
			window.Telegram.WebApp.expand();
			onTelegramSignCompleted();
			listenOnBackButton();
			return;
		}
		sessionStorage.removeItem("bckPage");

		// Init TWA
		try {
			if (window.Telegram?.WebApp) {
				window.Telegram.WebApp.ready();
				window.Telegram.WebApp.isClosingConfirmationEnabled = true;
				window.Telegram.WebApp.setHeaderColor("#000000", true);
				window.Telegram.WebApp.expand();

				// Click Event
				listenOnBackButton();
				listenOnMenuButton();
				listenOnViewPortChange();

				const user = window.Telegram.WebApp.initDataUnsafe?.user;
				if (user && window.Telegram.WebApp.initData) {
					dispatch(
						LoginAction({
							initData: window.Telegram.WebApp.initData,
						})
					);
					onTelegramSignCompleted();
				} else {
					onTelegramSignCompleted();
				}
			}
		} catch (err) {
			localStorage.removeItem("token");
			onTelegramSignCompleted();
			//console.log(`log1--->initializeTelegramWebApp 2`,err);
		}
	};

	const listenOnBackButton = () => {
		const gotBack = () => {
			const bckPage = sessionStorage.getItem("bckPage");
			if (bckPage === "close") {
				window.Telegram.WebApp.close();
			} else {
				navigate(bckPage || "/home");
			}
		};
		window.Telegram.WebApp.BackButton.onClick(() => {
			dispatch(ChatBackButtonClickAction());
			gotBack();
		});
	};

	const listenOnMenuButton = () => {
		window.Telegram.WebApp.onEvent("click", () => {
			dispatch(ChatPlayButtonClickAction());
		});
	};
	// Tracks viewport changes that may occur when the user opens the bot.
	const listenOnViewPortChange = () => {
		window.Telegram.WebApp.onEvent("viewportChanged", () => {
			dispatch(ChatStartClickAction());
		});
	};

	const onTelegramSignCompleted = () => {
		telegramState.initialized = true;
		telegramState.initializing = false;
		telegramState.notifyListeners();
	};

	return {
		isInitialized: () => {
			return telegramState.initialized;
		},
		isInitializing: () => {
			return telegramState.initializing;
		},
		initTelegram,
		addCompleteListener:
			telegramState.addCompleteListener.bind(telegramState),
	};
}
