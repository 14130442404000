import axios from "../../config/axiosConfig";
import { TOAST_SHOW } from "../actions/toastActions";
import { GET_REWARD_DATA_SUCCESS, GET_REWARD_DATA_FAILED, CHECK_REWARD_DATA_SUCCESS, CHECK_REWARD_DATA_FAILED  } from "../actions/rewardActions";
import { call, put } from "redux-saga/effects";

export function* getDailyRewardSaga(action) {
  try {
    const { data, status } = yield call(axios.get, "daily_rewards");
    if (status === 200 || status === 201 || status === 202) {
		yield put({  type: GET_REWARD_DATA_SUCCESS, payload: data }); 
        if(action?.successcallback){  
            let successcallback = action?.successcallback;
            successcallback(status,data);
        }
    }
  } catch (e) {
    yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true} });
		yield put({ type: GET_REWARD_DATA_FAILED });
  }
}

export function* checkDailyRewardSaga(action) {
  try {
    const { data, status } = yield call(axios.get, "check_rewards");
    if (status === 200 || status === 201 || status === 202) {
      localStorage.setItem("dailyRewardTimeStamp", Date.now());
        if(action?.successcallback){  
            let successcallback = action?.successcallback;
            successcallback(data);
        }
        yield put({  type: CHECK_REWARD_DATA_SUCCESS, payload: data }); 

    }
  } catch (e) {
    yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true} });
		yield put({ type: CHECK_REWARD_DATA_FAILED });
  }
}