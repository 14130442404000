import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FaChevronRight } from "react-icons/fa6";
import Footer from "../../components/Footer/Footer"
import { DeleteAccountAction } from '../../redux/actions/authActions';
import { useBackButton } from '../../hooks/useBackButton.jsx';
import { preventCloseWithSwipe } from '../../utils/preventCloseWithSwipe.js';
import { getPopupMessageAction } from '../../redux/actions/messageActions.js';
import { AnalyticsDeleteAccountAction } from '../../redux/actions/googleAnalyticsActions.js';


/*const languageOptions = [
  { title: "Select Language", description: "English" },
  { title: "Choose exchange", description: "BigX" },
  { title: "Delete account" },
];*/

const languageMap = {
  en: 'English',
  ar: 'العربي',
  ko: '한국인',
  pt: 'Português',
};

const _Version = import.meta.env.VITE_APP_VERSION;

const Settings = () => {
  useBackButton("/settings", "/home");

  const [isHapticCheck,setIsHapticCheck] = useState(() => {
    const storedValue = localStorage.getItem('is_haptic');
    return storedValue === 'true';
  });
  const [isAnimationCheck,setIsAnimationCheck] = useState(() => {
    const storedValue = localStorage.getItem('is_animation');
    return storedValue === 'true';
  });
  const navigate = useNavigate();
  const { t,i18n  } = useTranslation();
  const dispatch = useDispatch();

  const currentLanguageCode = i18n?.language ? i18n?.language: 'en';
  const currentLanguageName = languageMap[currentLanguageCode] || 'English';

  useEffect(() => {
    dispatch(getPopupMessageAction());
    preventCloseWithSwipe();
  }, []);

  const handleAnimationSwitchChange = () => {
    setIsAnimationCheck(!isAnimationCheck);
    localStorage.setItem(`is_animation`,!isAnimationCheck)
  };

  const handleLanguageChange = () => {
    navigate(`/change-language`)
  }

  const handleDeleteAccount=()=>{
    let okBtnTxt =  t('general.popup_ok');
    let cancelBtnTxt =  t('general.popup_cancel');

    window.Telegram.WebApp.showPopup(
		{
			title: t("settings.delete_popup_title"),
			message: t("settings.delete_popup_text"),
			buttons: [
				{ id: "delete_ok", type: "default", text: okBtnTxt },
        { id: "cancel", text: cancelBtnTxt },
			],
		},
		function (btn) {
			if (btn === "delete_ok") {
        dispatch(AnalyticsDeleteAccountAction());

				const successcallback = async () => {  
					localStorage.clear();
          sessionStorage.clear();
          
					localStorage.removeItem("points");
					localStorage.removeItem("user");
					localStorage.removeItem("token");
					localStorage.removeItem("is_haptic");
					localStorage.removeItem("is_animation");
					localStorage.removeItem("is_sound");
          
					window.Telegram.WebApp.close();
				};
				dispatch(DeleteAccountAction(successcallback));
			}
		}
	);


  }


  const handleHapticSwitchChange =()=>{
    setIsHapticCheck(!isHapticCheck);
    localStorage.setItem(`is_haptic`,!isHapticCheck)
  }

  return (
    <div className="settingsPage text-white px-[20px]">
      {/* <div className="flex items-center justify-around py-0 px-1">
        <button className="flex items-center text-white text-xs p-2" onClick={goBack}>
          <ArrowLeftIcon /></button>
          <div className="font-bold justify-center gap-2 flex items-center whitespace-nowrap text-md text-white">
            <img className="w-6 h-6" src={star} alt='star'/> ASSAD <img  className="w-6 h-6"  src={star} alt='star'/>
          </div>
        <Dropdownmenu />
      </div> */}


      <h1 className="text-center font-bold text-[30px] my-3 pt-2">{t('settings.title')}</h1>
      <div>
          <div className="flex items-center justify-between gap-[16px] h-[75px] rounded-[30px] bottom-2.5 inset-x-2.5 bg-gradient-to-r from-[#454849] to-[#2d3032] py-2.5 px-4 my-4 cursor-pointer" onClick={handleLanguageChange}>
            <div>
              <h3 className="font-medium text-lg">{t('settings.select_language')}</h3>
              <span className="text-[14px] text-[#86898e]">{currentLanguageName}</span>
            </div>
            <FaChevronRight className="text-[#86898e] h-6 w-6" />
          </div>

          <div className="flex items-center justify-between gap-[16px] h-[75px] rounded-[30px] bottom-2.5 inset-x-2.5 bg-gradient-to-r from-[#454849] to-[#2d3032] py-2.5 px-4  my-3 cursor-pointer" onClick={handleDeleteAccount}>
            <div>
              <h3 className="font-medium text-lg">{t('settings.delete')}</h3>
            </div>
            <FaChevronRight className="text-[#86898e] h-6 w-6" />
          </div>

          {/* {languageOptions.map((option, index) => (
            <div key={`lang_${index}`} className="flex items-center justify-between gap-[16px] h-[65px] rounded-[10px] bottom-2.5 inset-x-2.5 bg-[#272a2f] p-2 my-3">
              <div>
                <h3 className="font-bold">{option.title}</h3>
                <span className="text-[14px] text-[#86898e]">{option.description}</span>
              </div>
              <FaChevronRight className="text-[#86898e]" />
            </div>
          ))} */}
      </div>

      <div className='flex flex-column gap-3 my-3'>
        <label className="flex items-center cursor-pointer justify-between">
          <span className="text-md font-medium">{t('settings.haptic')}</span>
          <div className='checkBoxWrapper'>
            <input
              type="checkbox"
              className="sr-only peer w-14 h-6"
              checked={isHapticCheck}
              onChange={handleHapticSwitchChange}
            />
            {
              isHapticCheck ?
              <div className={` inputcheckbox relative  w-[60px] h-8  border-2[#ffd742] border-[#ffd742] bg-[#161a1c] rounded-full ${isHapticCheck ? 'peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-[#ffd742] peer-checked:bg-[#ffd742]' : 'border-2[#ffd742]'} after:content-[''] after:absolute after:right-[0px] after:top-[0.3rem] after:start-[14px] after:bg-gradient-to-r from-[#454849] to-[#2d3032] after:border-[#ffd742]  after:rounded-full after:h-5 after:w-5 after:transition-all`} />
              :
              <div className={` inputcheckbox relative  w-[60px] h-8  border-2[#ffd742] border-[#ffd742] bg-[#161a1c] rounded-full ${isHapticCheck ? 'peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-[#161a1c] peer-checked:bg-[#161a1c]' : 'border-2[#ffd742]'} after:content-[''] after:absolute after:top-[0.3rem] after:start-[5px] after:bg-[#ffd742] after:border-[#161a1c]  after:rounded-full after:h-5 after:w-5 after:transition-all`} />
            }

          </div>
        </label>
        <label className="flex items-center cursor-pointer justify-between mt-[20px]">
          <span className="text-md font-bold">{t('settings.version')}</span>
          <div>{_Version}</div>
        </label>
     {/* <label className="flex items-center cursor-pointer justify-between mt-[20px]">
          <span className="text-md font-bold">{t('settings.coin_animation')}</span>
          <div>
            <input
              type="checkbox"
              className="sr-only peer"
              checked={isAnimationCheck}
              onChange={handleAnimationSwitchChange}
            />

            {
              isAnimationCheck ?
              <div className={` inputcheckbox relative  w-11 h-6  border-2[#ffd742] border-[#ffd742] bg-[#161a1c] rounded-full ${isAnimationCheck ? 'peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-[#ffd742] peer-checked:bg-[#ffd742]' : 'border-2[#ffd742]'} after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-[#292929] after:border-[#ffd742]  after:rounded-full after:h-5 after:w-5 after:transition-all`} />
              :
              <div className={` inputcheckbox relative  w-11 h-6  border-2[#ffd742] border-[#ffd742] bg-[#161a1c] rounded-full ${isAnimationCheck  ? 'peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-[#161a1c] peer-checked:bg-[#161a1c]' : 'border-2[#ffd742]'} after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-[#ffd742] after:border-[#161a1c]  after:rounded-full after:h-5 after:w-5 after:transition-all`} />
            }

          </div>
        </label>//*/}
      </div>
      <Footer />
    </div>
  )
}

export default Settings
