import { call, put } from "redux-saga/effects";

import axios from "../../config/axiosConfig";
import { GET_POPUP_MESSAGE_SUCCESS, GET_POPUP_MESSAGE_FAILED,MESSAGE_READ_FAILED,MESSAGE_READ_SUCCESS } from "../actions/messageActions";
import { TOAST_SHOW } from "../actions/toastActions"; 

export function* getPopupMessageSaga(action) { 
	try {
		const response = yield call(axios.get, `get_messages`); 

		if (response?.status === 200) {  
			yield put({
				type: GET_POPUP_MESSAGE_SUCCESS,
				payload: response?.data, 
			});
		}
	} catch (e) {
		yield put({
			type: TOAST_SHOW,
			payload: { message: "Something Went wrong please try again", severity: "Danger", show: true},
		});
		yield put({ type: GET_POPUP_MESSAGE_FAILED });
	}
}

export function* readMessageSaga(action) { 
	try {
		const response = yield call(axios.post, `userreadmessage`, action.payload); 

		if (response?.status === 200) {  
			yield put({ type: MESSAGE_READ_SUCCESS, payload: response?.data });
			if(action?.successcallback){  
				let successcallback = action?.successcallback;
				successcallback();
			}
		}
	} catch (e) {
		yield put({
			type: TOAST_SHOW,
			payload: { message: "Something Went wrong please try again", severity: "Danger", show: true},
		});
		yield put({ type: MESSAGE_READ_FAILED });
	}
}