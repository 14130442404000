import { call, put } from "redux-saga/effects";

import axios from "../../config/axiosConfig";
import { GET_EARN_DATA_SUCCESS,GET_EARN_DATA_FAILED,CHECK_EARN_DATA_FAILED,CHECK_EARN_DATA_SUCCESS } from "../actions/earnActions";
import { TOAST_SHOW } from "../actions/toastActions"; 

/**
 * Saga function to fetch earn data from the server.
 * On success, it dispatches the GET_EARN_DATA_SUCCESS action with the data.
 * On failure, it dispatches the TOAST_SHOW action to show an error message and the GET_EARN_DATA_FAILED action.
 */
export function* getEarnDataSaga() {
  try {
    // Make a GET request to the "earn" endpoint
    const { data } = yield call(axios.get, "earn");

    // Dispatch the GET_EARN_DATA_SUCCESS action with the fetched data
    yield put({ type: GET_EARN_DATA_SUCCESS, payload: data });
  } catch (error) {
    // Dispatch the TOAST_SHOW action to show an error message
    yield put({ type: TOAST_SHOW, payload: { message: "Error fetching earn data", severity: "Danger", show: true } });
    // Dispatch the GET_EARN_DATA_FAILED action
    yield put({ type: GET_EARN_DATA_FAILED });
  }
}



export function* checkEarnDataSaga(action) { 
	try { 
    const { data,status } = yield call(axios.post, "claim_referrals", action.payload); 

		if (status === 200 || status === 201 || status === 202) {
      
			yield put({  type: CHECK_EARN_DATA_SUCCESS, payload: data }); 
      
      if(action?.successcallback){  
				let successcallback = action?.successcallback;
				successcallback(status,data);
			}
		}
	} catch (e) {
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true} });
		yield put({ type: CHECK_EARN_DATA_FAILED });
	}
} 