import { call, put } from "redux-saga/effects";

import axios from "../../config/axiosConfig";
import {
	GET_USER_FOUND,
	LOGOUT_SUCCESS,
	GET_USER_NOT_FOUND,
	LOGOUT_ERROR,
	DELETE_ACCOUNT_SUCCESS,
	DELETE_ACCOUNT_FAILED,
	UPDATE_LANGUAGE_SUCCESS,
	UPDATE_LANGUAGE_FAILED,
} from "../actions/authActions";
import { TOAST_SHOW } from "../actions/toastActions";

//import { removeCookie, setCookie } from "../../utils/functions";

export function* LoginSaga(action) {
	try {
		const response = yield call(axios.post, `login`, action.payload);
		if (response?.status === 200) {
			yield sessionStorage.setItem("token", response?.data?.accessToken);

			yield put({
				type: GET_USER_FOUND,
				payload: {
					...response?.data
				},
				token: response?.data?.accessToken,
			});
		}
	} catch (e) {
		yield put({
			type: TOAST_SHOW,
			payload: {
				message: "Something Went wrong please try again",
				severity: "Danger",
				show: true,
			},
		});
		yield put({ type: GET_USER_NOT_FOUND });
	}
}

export function* LogoutSaga(action) {
	try {
		const response = yield call(axios.get, "logout");

		if (response?.status === 200) {
			yield put({ type: LOGOUT_SUCCESS });

			if (action?.successcallback) {
				let successcallback = action?.successcallback;
				successcallback();
			}
		}
	} catch (e) {
		yield put({
			type: TOAST_SHOW,
			payload: {
				message: "Something Went wrong please try again",
				severity: "Danger",
				show: true,
			},
		});
		yield put({ type: LOGOUT_ERROR });
	}
}

export function* DeleteAccountSaga(action) {
	try {
		const response = yield call(axios.get, "settings/delete_account");
		if (response?.status === 200) {
			yield put({ type: DELETE_ACCOUNT_SUCCESS });

			if (action?.successcallback) {
				let successcallback = action?.successcallback;
				successcallback();
			}
		}
	} catch (e) {
		yield put({ type: DELETE_ACCOUNT_FAILED });
	}
}

export function* UpdateLanguageSaga(action) {
	try {
		const response = yield call(axios.post, `update_lang`, action.payload);

		if (response?.status === 200) {
			yield put({
				type: UPDATE_LANGUAGE_SUCCESS,
				payload: response?.data,
			});
		}
	} catch (e) {
		yield put({
			type: TOAST_SHOW,
			payload: {
				message: "Something Went wrong please try again",
				severity: "Danger",
				show: true,
			},
		});
		yield put({ type: UPDATE_LANGUAGE_FAILED });
	}
}
