import { all, takeEvery } from "redux-saga/effects";
import ReactGA from '../../googleAnalytics'
import { 
  GAME_OPEN_VIEW, 
  GAME_START_CLICK,
  GAME_ROAR_CLICK,
  GAME_TAP_CLICK,
  GAME_DAILY_BONUS_CLICK,
  GAME_FRIENDS_CLICK,
  GAME_FRIENDS_INVITE_CLICK,
  GAME_EARN_CLICK,
  GAME_FOLLOW_X_CLICK,
  GAME_FOLLOW_TG_CLICK,
  GAME_AIRDROP_CLICK,
  GAME_CONNECT_WALLET_CLICK,
  GAME_GAMES_CLICK,
  CHAT_PLAYBUTTON_CLICK,
  CHAT_START_CLICK,
  CHAT_BACKBUTTON_CLICK,
  GAME_SETTINGS_CLICK,
  GAME_LANGCHANGE_CLICK,
  GAME_LEVELUP_CLICK,
  GAME_LEADERBOARD_CLICK,
  GAME_LEADERBOARD_LEVEL_VIEW,
  GAME_DELETEACCOUNT_CLICK
 } from "../actions/googleAnalyticsActions"
 
function* WatchGoogleAnalyticsEventsSaga() {
    yield all([
      takeEvery(GAME_OPEN_VIEW, function* send() { yield ReactGA.event({ category: 'game', action: 'Game_open_View' }) }),
      takeEvery(GAME_START_CLICK, function* send() { yield ReactGA.event({ category: 'game', action: 'GAME_START_CLICK' }) }),
      takeEvery(GAME_TAP_CLICK, function* send() { yield ReactGA.event({ category: 'game', action: 'GAME_TAP_CLICK' }) }),
      takeEvery(GAME_ROAR_CLICK, function* send() { yield ReactGA.event({ category: 'game', action: 'Game_Roar_Click' }) }),
      takeEvery(GAME_DAILY_BONUS_CLICK, function* send() { yield ReactGA.event({ category: 'game', action: 'Game_DailyBonus_Click' }) }),
      takeEvery(GAME_FRIENDS_CLICK, function* send() { yield ReactGA.event({ category: 'game', action: 'Game_Friends_Click' }) }),
      takeEvery(GAME_FRIENDS_INVITE_CLICK, function* send() { yield ReactGA.event({ category: 'game', action: 'Game_FriendsInvite_Click' }) }),
      takeEvery(GAME_EARN_CLICK, function* send() { yield ReactGA.event({ category: 'game', action: 'Game_Earn_Click' }) }),
      takeEvery(GAME_FOLLOW_X_CLICK, function* send() { yield ReactGA.event({ category: 'game', action: 'Game_FollowX_Click' }) }),
      takeEvery(GAME_FOLLOW_TG_CLICK, function* send() { yield ReactGA.event({ category: 'game', action: 'Game_FollowTG_Click' }) }),
      takeEvery(GAME_AIRDROP_CLICK, function* send() { yield ReactGA.event({ category: 'game', action: 'Game_Airdrop_Click' }) }),
      takeEvery(GAME_CONNECT_WALLET_CLICK, function* send() { yield ReactGA.event({ category: 'game', action: 'Game_ConnectWallet_Click' }) }),
      takeEvery(GAME_GAMES_CLICK, function* send() { yield ReactGA.event({ category: 'game', action: 'Game_Games_Click' }) }),
      takeEvery(CHAT_PLAYBUTTON_CLICK, function* send() { yield ReactGA.event({ category: 'chat', action: 'CHAT_PLAYBUTTON_CLICK' }) }),
      takeEvery(CHAT_START_CLICK, function* send() { yield ReactGA.event({ category: 'chat', action: 'CHAT_START_CLICK' }) }),
      takeEvery(CHAT_BACKBUTTON_CLICK, function* send() { yield ReactGA.event({ category: 'chat', action: 'CHAT_BACKBUTTON_CLICK' }) }),
      takeEvery(GAME_SETTINGS_CLICK, function* send() { yield ReactGA.event({ category: 'game', action: 'Game_Settings_Click' }) }),
      takeEvery(GAME_LANGCHANGE_CLICK, function* send(data) { yield ReactGA.event({ category: 'game', action: 'Game_LangChange_Click',language: data?.payload?.lang?.code }) }),
      takeEvery(GAME_LEVELUP_CLICK, function* send() { yield ReactGA.event({ category: 'game', action: 'Game_LevelUp_Click' }) }),
      takeEvery(GAME_LEADERBOARD_CLICK, function* send() { yield ReactGA.event({ category: 'game', action: 'Game_LeaderBoard_Click' }) }),
      takeEvery(GAME_LEADERBOARD_LEVEL_VIEW, function* send(data) {  yield ReactGA.event({ category: 'game', action: 'Game_LeaderBoardLevel_view', level: data?.payload?.level })  }),
      takeEvery(GAME_DELETEACCOUNT_CLICK, function* send() { yield ReactGA.event({ category: 'game', action: 'Game_DeleteAccount_Click' }) }), 
    ]);
}

export default WatchGoogleAnalyticsEventsSaga