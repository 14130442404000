export const GAME_OPEN_VIEW ='Game_open_View';
export const GAME_START_CLICK ='GAME_START_CLICK';
export const GAME_TAP_CLICK = 'GAME_TAP_CLICK'
export const GAME_ROAR_CLICK = 'Game_Roar_Click'
export const GAME_DAILY_BONUS_CLICK = 'Game_DailyBonus_Click'
export const GAME_FRIENDS_CLICK = 'Game_Friends_Click'
export const GAME_FRIENDS_INVITE_CLICK = 'Game_FriendsInvite_Click'
export const GAME_EARN_CLICK = 'Game_Earn_Click'
export const GAME_FOLLOW_X_CLICK = 'Game_FollowX_Click'
export const GAME_FOLLOW_TG_CLICK = 'Game_FollowTG_Click'
export const GAME_AIRDROP_CLICK = 'Game_Airdrop_Click'
export const GAME_CONNECT_WALLET_CLICK = 'Game_ConnectWallet_Click'
export const GAME_GAMES_CLICK = 'Game_Games_Click'

export const GAME_SETTINGS_CLICK = 'Game_Settings_Click'

export const CHAT_START_CLICK = 'CHAT_START_CLICK'
export const CHAT_PLAYBUTTON_CLICK = 'CHAT_PLAYBUTTON_CLICK'

export const CHAT_BACKBUTTON_CLICK = 'CHAT_BACKBUTTON_CLICK'

export const GAME_LANGCHANGE_CLICK = 'Game_LangChange_Click'
export const GAME_DELETEACCOUNT_CLICK = 'Game_DeleteAccount_Click'

export const GAME_LEVELUP_CLICK = 'Game_LevelUp_Click'

export const GAME_LEADERBOARD_CLICK = 'Game_LeaderBoard_Click'
export const GAME_LEADERBOARD_LEVEL_VIEW = 'Game_LeaderBoardLevel_view'
 

export const GameOpenViewAction = (data) => {
	return { type: GAME_OPEN_VIEW, payload: data };
};

export const GameStartClickAction = (data) => {
	return { type: GAME_START_CLICK, payload: data };
};

export const GameRoarClickAction = (data) => {
	return { type: GAME_ROAR_CLICK, payload: data };
}

export const GameTapClickAction = (data) => {
	return { type: GAME_TAP_CLICK, payload: data };
}

export const GameDailyBonusClickAction = (data) => {
	return { type: GAME_DAILY_BONUS_CLICK, payload: data };
}

export const GameFiendsClickAction = (data) => {
	return { type: GAME_FRIENDS_CLICK, payload: data };
}

export const GameFriendsInviteClickAction = (data) => {
	return { type: GAME_FRIENDS_INVITE_CLICK, payload: data };
}

export const GameEarnClickAction = (data) => {
	return { type: GAME_EARN_CLICK, payload: data };
}

export const GameFollowXClickAction = (data) => {
	return { type: GAME_FOLLOW_X_CLICK, payload: data };
}

export const GameFollowTgClickAction = (data) => {
	return { type: GAME_FOLLOW_TG_CLICK, payload: data };
}

export const GameAirdropClickAction = (data) => {
	return { type: GAME_AIRDROP_CLICK, payload: data };
}

export const GameConnectWalletClickAction = (data) => {
	return { type: GAME_CONNECT_WALLET_CLICK, payload: data };
}

export const GameGamesClickAction = (data) => {
	return { type: GAME_GAMES_CLICK, payload: data };
}

export const ChatStartClickAction = (data) => {
	return { type: CHAT_START_CLICK, payload: data };
}

export const ChatPlayButtonClickAction = (data) => {
	return { type: CHAT_PLAYBUTTON_CLICK, payload: data };
}

export const ChatBackButtonClickAction = (data) => {
	return { type: CHAT_BACKBUTTON_CLICK, payload: data };
}

export const GameSettingsClickAction = (data) => {
	return { type: GAME_SETTINGS_CLICK, payload: data };
}

export const LangChangeClickAction = (data) => {
	return { type: GAME_LANGCHANGE_CLICK, payload: data };
}

export const AnalyticsDeleteAccountAction = (data) => {
	return { type: GAME_DELETEACCOUNT_CLICK, payload: data };
}

export const levelUpAnalyticsAction = (data) => {
	return { type: GAME_LEVELUP_CLICK, payload: data };
}

export const GameLeaderboardClickAction = (data) => {
	return { type: GAME_LEADERBOARD_CLICK, payload: data };
}

export const GameLeaderboardLevelViewAction = (data) => {
	return { type: GAME_LEADERBOARD_LEVEL_VIEW, payload: data };
}
 


