
import dollarCoinImage from '../assets/images/confetti/dollarCoin.png';
import friendCoinImage from '../assets/images/confetti/friendCoin.png';
import lionCoinImage from '../assets/images/confetti/lionCoin.png';
import tgLogoImage from '../assets/images/confetti/tgChannel.png';
import xSocialLogoImage from '../assets/images/confetti/xSocial.png';


const fireConfetti = () => {
  confetti({
    spread: 360,
    ticks: 200,
    gravity: 1,
    decay: 0.94,
    startVelocity: 30,
    particleCount: 100,
    scalar: 3,
    shapes: ["image"],
    shapeOptions: {
      image: [{
        src: dollarCoinImage,
        width: 32,
        height: 32,
      }],
    },
  });
}

const fireXsocialConfetti = () => {
  confetti({
    spread: 360,
    ticks: 200,
    gravity: 1,
    decay: 0.94,
    startVelocity: 30,
    particleCount: 100,
    scalar: 3,
    shapes: ["image"],
    shapeOptions: {
      image: [{
        src: lionCoinImage,
        width: 32,
        height: 32,
      },{
        src: xSocialLogoImage,
        width: 32,
        height: 32,
      }],
    },
  });
}

const fireTgChannelConfetti = () => {
  confetti({
    spread: 360,
    ticks: 200,
    gravity: 1,
    decay: 0.94,
    startVelocity: 30,
    particleCount: 100,
    scalar: 3,
    shapes: ["image"],
    shapeOptions: {
      image: [{
        src: lionCoinImage,
        width: 32,
        height: 32,
      },{
        src: tgLogoImage,
        width: 32,
        height: 32,
      }],
    },
  });
}



const fireFriendConfetti = () => {
  confetti({
    spread: 360,
    ticks: 200,
    gravity: 1,
    decay: 0.94,
    startVelocity: 30,
    particleCount: 100,
    scalar: 3,
    shapes: ["image"],
    shapeOptions: {
      image: [{
        src: lionCoinImage,
        width: 32,
        height: 32,
      },{
        src: friendCoinImage,
        width: 32,
        height: 32,
      }],
    },
  });
}


export { fireConfetti , fireXsocialConfetti,fireTgChannelConfetti,fireFriendConfetti };
