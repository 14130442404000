import { call, put } from "redux-saga/effects";

import axios from "../../config/axiosConfig";
import { CHECK_FRIENDS_FAILED,CHECK_FRIENDS_SUCCESS,CLAIM_REWARD_FRIENDS_SUCCESS,CLAIM_REWARD_FRIENDS_FAILED } from "../actions/friendsActions";
import { TOAST_SHOW } from "../actions/toastActions";

export function* checkFriendsSagaOld() {
  try {
    const { data } = yield call(axios.get, "checkfriends");
    yield put({ type: CHECK_FRIENDS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TOAST_SHOW, payload: { message: "Error fetching earn data", severity: "Danger", show: true } });
    yield put({ type: CHECK_FRIENDS_FAILED });
  }
}

export function* checkFriendsSaga(action) {
  try {
    const { data,status }  = yield call(axios.get, "checkfriends");
		if (status === 200) {
      yield put({ type: CHECK_FRIENDS_SUCCESS, payload: data });
      if (action?.successcallback){
        action.successcallback();
      }
    }
  } catch (error) {
    yield put({ type: TOAST_SHOW, payload: { message: "Error fetching earn data", severity: "Danger", show: true } });
    yield put({ type: CHECK_FRIENDS_FAILED });
  }
}

export function* claimRewardsFriendsSaga(action) {
	try {
    const { data,status } = yield call(axios.post, "claim_referrals", action.payload);

		if (status === 200 || status === 201 || status === 202) {
      //console.log(`369--->data`,data)
			yield put({  type: CLAIM_REWARD_FRIENDS_SUCCESS, payload: data });

      if (action?.successcallback) {
				let successcallback = action?.successcallback;
				successcallback(data);
			}
		}
	} catch (e) {
    if (action?.errorCallback) {
      let errorCallback = action?.errorCallback;
      errorCallback();
    }
		yield put({ type: TOAST_SHOW, payload: { message: "Something Went wrong please try again", severity: "Danger", show: true} });
		yield put({ type: CLAIM_REWARD_FRIENDS_FAILED });
	}
 }
